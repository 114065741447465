import React, { useState } from 'react';
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Grid,
  Box
} from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Phone as PhoneIcon } from '@mui/icons-material';

const reminder = {
  ru:(room, slot, capacity) => {
    return <p style={{textAlign:'left', marginLeft:"15px"}}>Напоминание: Вы бронируете <b>{room}</b> с вместительностью <b>{capacity}</b> человек на 30.09.2024 <b>{slot}</b></p>
  },
  en:(room, slot, capacity) => {
    return <p style={{textAlign:'left', marginLeft:"15px"}}>Reminder: You are booking <b>{room}</b> for 30.09.2024 <b>{slot}</b> which's capacity is <b>{capacity}</b> persons</p>
  }
}

const countries = {
    en:[
        { value: 'Armenia', label: '🇦🇲 Armenia' },
        { value: 'Belarus', label: '🇧🇾 Belarus' },
        { value: 'Kazakhstan', label: '🇰🇿 Kazakhstan' },
        { value: 'Kyrgyzstan', label: '🇰🇬 Kyrgyzstan' },
        { value: 'Russia', label: '🇷🇺 Russian Federation' },
        { value: 'Other', label: '🌍 Other' }
      ],
    ru:[
        { value: 'Armenia', label: '🇦🇲 Армения' },
        { value: 'Belarus', label: '🇧🇾 Беларусь' },
        { value: 'Kazakhstan', label: '🇰🇿 Казахстан' },
        { value: 'Kyrgyzstan', label: '🇰🇬 Кыргызстан' },
        { value: 'Russia', label: '🇷🇺 Российская Федерация' },
        { value: 'Other', label: '🌍 Другое' }
      ]
};

const MyForm = ({formData, setFormData, bookSlot, message, sending, language, room, slot, capacity}) => {
    console.log(language)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneNumberChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
  };

  const text = {
    en: {
      fullName: "Full Name",
      email: "Email",
      jobTitle: "Job Title",
      phone: "Enter phone number",
      citizenship: "Citizenship",
      comment: "Optional Comment",
      submit: sending ? "Sending..." : "Submit"
    },
    ru: {
      fullName: "Полное имя",
      email: "Электронная почта",
      jobTitle: "Должность",
      phone: "Введите номер телефона",
      citizenship: "Гражданство",
      comment: "Комментарий (необязательно)",
      submit: sending ? "Отправка..." : "Отправить"
    }
  };

  const { fullName, email, jobTitle, phone, citizenship, comment, submit } = text[language];

  return (
    <Box component="form" onSubmit={bookSlot} sx={{ width: '100%', maxWidth: 600, margin: 'auto', }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={fullName}
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={email}
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={jobTitle}
            name="jobTitle"
            value={formData.jobTitle}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            defaultCountry="US"
            value={formData.phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder={phone}
            international
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid className='citizenship' item xs={12}>
          <FormControl fullWidth>
            <InputLabel>{citizenship}</InputLabel>
            <Select
              name="citizenship"
              value={formData.citizenship}
              onChange={handleChange}
              required
            >
              {countries[language].map((country) => (
                <MenuItem key={country.value} value={country.value}>
                  {country.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={comment}
            name="comment"
            value={formData.comment}
            onChange={handleChange}
            multiline
            rows={4}
          />
        </Grid>
        {reminder[language](room, slot, capacity)}
        {message && <p className="message">{message}</p>}
        <Grid item xs={12}>
          <Button disabled={sending} style={{width:"100%"}} type="submit" variant="contained" color="primary">
            {submit}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyForm;