import React, { useState, useEffect } from 'react';
import request from './request';  // Import the request module
import './App.css';
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Grid,
  Box
} from '@mui/material';
import MyForm from './MyForm';
import Admin from './Admin';

const rooms = ['Room 1', 'Room 2', 'Room 3', 'Room 4'];
const rooms_ru = ['Зал 1', 'Зал 2', 'Зал 3', 'Зал 4'];
const capacity = [6,20,20,20]
const room_info = {
  en:[
    "capacity - 6 persons",
    "capacity - 20 persons",
    "capacity - 20 persons",
    "capacity - 20 persons"
  ],
  ru:[
    "макс. - 6 человек",
    "макс. - 20 человек",
    "макс. - 20 человек",
    "макс. - 20 человек"
  ]
}

const App = () => {
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);
    const [formData, setFormData] = useState({
      fullName: '',
      jobTitle: '',
      email: '',
      citizenship: '',
      phoneNumber: '',
      comment: '',
    });
    const [language, setLanguage] = useState('en');  // Language state

    // Check hash and set language accordingly
    useEffect(() => {
      if (window.location.hash === '#/ru') {
        setLanguage('ru');
      } else {
        setLanguage('en');
      }
    }, [window.location.hash]);

    const fetchAvailableSlots = async (room) => {
        setSelectedRoom(room);
        setSelectedSlot('');
        setMessage('');
        try {
            const response = await request.get(`/rooms/${room}/slots`);
            setAvailableSlots(response.data.availableSlots);
        } catch (error) {
            console.error('Error fetching slots:', error);
        }
    };

    const bookSlot = async (e) => {
        e.preventDefault();
        if (!formData.fullName || !formData.jobTitle || !formData.email || !formData.phoneNumber) {
            setMessage(language === 'ru' ? 'Пожалуйста, заполните все обязательные поля.' : 'Please fill in all required fields.');
            return;
        }

        try {
            setSending(true);
            const response = await request.post('/rooms/book', {
                room: selectedRoom,
                timeSlot: selectedSlot,
                formData,
                language
            });
            setMessage(response.data.message);
            setAvailableSlots(availableSlots.map(slot => slot.slot !== selectedSlot ? slot : { ...slot, available: false }));
            setFormData({
              fullName: '',
              jobTitle: '',
              email: '',
              citizenship: '',
              phoneNumber: '',
              comment: '',
            });
            setSending(false);
        } catch (error) {
            setMessage(error.response?.data?.message || (language === 'ru' ? 'Ошибка при бронировании.' : 'Error booking slot.'));
        }
    };

    if (window.location.hash === '#/admin-panel') {
      return <Admin />;
    }

    const text = {
      en: {
        title: "Choose Room to book for 30th September 2024",
        instruction: "Here you can find available times for your B2B meetings and make room reservation for them.",
        steps: [
          "Choose the room and the time slot",
          "Complete the form",
          "Receive request confirmation E-mail",
          "Wait for administrator's approval E-mail"
        ],
        noSlots: "Loading...."
      },
      ru: {
        title: "Выберите зал для бронирования на 30 сентября 2024",
        instruction: "Здесь вы можете найти доступное время для ваших встреч B2B и забронировать зал.",
        steps: [
          "Выберите зал и время",
          "Заполните форму",
          "Получите подтверждение по электронной почте",
          "Ожидайте письма с подтверждением от администратора"
        ],
        noSlots: "Загрузка..."
      }
    };

    const { title, instruction, steps, noSlots } = text[language];

    return (
        <div className="container">
          <div className="info-container">
            <div className="left">
              {instruction}
            </div>
            <div className="right">
              <ol>
                {steps.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </ol>
            </div>
          </div>
          <div className="bordered-container">
            <h1>{title}</h1>
            <div className="rooms">
                {rooms.map((room,i) => (
                  <div className='btn-block'>
                    <button style={{ backgroundColor: selectedRoom === room ? '#0E2543' : '' }} key={room} className="room-btn" onClick={() => fetchAvailableSlots(room)}>
                        {language === 'ru' ? rooms_ru[rooms.indexOf(room)] : room}
                    </button>
                    <p>{room_info[language][i]}</p>
                  </div>
                ))}
            </div>

            {selectedRoom && (
                <div className="booking-cont">
                    <h2>{language === 'ru' ? `Доступные временные интервалы для ${language === 'ru' ? rooms_ru[rooms.indexOf(selectedRoom)] : selectedRoom}` : `Available Time Slots for ${language === 'ru' ? rooms_ru[rooms.indexOf(selectedRoom)] : selectedRoom}`}</h2>
                    <div className="slots">
                        {availableSlots.length > 0 ? (
                            availableSlots.map((slot) => (
                                <button style={{ backgroundColor: selectedSlot === slot.slot ? '#0E2543' : '' }} disabled={!slot.available} key={slot.slot} className="slot-btn" onClick={() => slot.available ? setSelectedSlot(slot.slot) : ''}>
                                    {slot.slot}
                                </button>
                            ))
                        ) : (
                            <p>{noSlots}</p>
                        )}
                    </div>

                    {selectedSlot && (
                        <div className="form">
                            <h3>{language === 'ru' ? `Выбранное время: ${selectedSlot}` : `Selected Slot: ${selectedSlot}`}</h3>
                            <MyForm  
                              formData={formData} 
                              setFormData={setFormData} 
                              bookSlot={bookSlot} 
                              message={message} 
                              sending={sending} 
                              language={language} 
                              room={language == "ru" ? rooms_ru[rooms.findIndex(r => r == selectedRoom)] : selectedRoom}
                              slot={selectedSlot}
                              capacity={capacity[rooms.findIndex(r => r == selectedRoom)]}
                            />
                        </div>
                    )}
                </div>
            )}
          </div>
        </div>
    );
};

export default App;