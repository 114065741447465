import React, { useState, useEffect } from 'react';
import request from './request';  // Import the request module
import { Button } from '@mui/material';
import { Language } from '@mui/icons-material';

const Admin = () => {
    const [bookings, setBookings] = useState([]);

    const updateBookings = () => {
        request.get('/bookings')
        .then((res) => {
            console.log(res)
            
            setBookings(res.data.reverse())
        })
    }

    useEffect(() => {
        updateBookings();
    }, [])
    console.log(bookings)
    return (
        <div>
            {
                bookings.map((booking) => {
                    console.log(booking)
                    // console.log(booking.formData.entries())
                    return (
                        <div className="booking">
                            <p style={{backgroundColor: !booking.approved ? 'red' : 'green'}} className='approved'>{booking.approved ? 'Approved' : "Not Approved"}</p>
                            {Object.entries({room:booking.room, timeSlot:booking.timeSlot, ...booking.formData}).map(([key, value]) => {
                                console.log(key, value);
                                return (
                                    <p>{key} - {value}</p>
                                )
                            })}
                            {
                                booking.approved ? 
                                    <Button variant='contained' color='error' onClick={() => request.get('/' + booking._id + '/disprove').then(() => updateBookings())}>Remove</Button>
                                :
                                    <div>
                                        <Button variant='contained' onClick={() => request.post('/' + booking._id + '/approve', {language:booking.language}).then(() => updateBookings())}>Approve</Button>
                                        <Button style={{marginLeft:"10px"}} variant='contained' color='error' onClick={() => request.get('/' + booking._id + '/disprove').then(() => updateBookings())}>Remove</Button>
                                    </div>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
};

export default Admin;